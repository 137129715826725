import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import { USER_DATA } from "./services/storageService/storageKeys";
import { storageService } from "./services/storageService/storageService";
import HomeView from "./views/Home";
import RegisterView from "./views/Register";
import LoginView from "./views/Login";
import PaymentStatusView from "./views/Payment";
import StatisticsView from "./views/Statistics";
import ProfileView from "./views/Profile";
import AdminPanelView from "./views/AdminPanel";
import TestView from "./views/test/Test";
import ResetPwd from "./views/ResetPwd";
import SelectGVA from "./views/test/simulacrum/SelectGVA";
import SelectLocal from "./views/test/simulacrum/SelectLocal";
import NavigationBar from "./components/navigationBar/navigationBar";
import FAQSView from "./views/FAQS";
import RecoverPwd from './views/RecoverPwd';
import SelectTest from './views/test/SelectType';
import SelectSimulacrum from './views/test/simulacrum/SelectType';
import SelectSubject from './views/test/regulations/SelectSubject';
import SelectStandard from './views/test/regulations/SelectStandard';
import ReportReviewerPanelView from './views/ReportReviewer';
import TesUnfinishedView from './views/RecoverTestsUnfinished';
import SelectCustomOrlaw from './views/test/regulations/SelectCustomOrLaw';
import SelectCustomGVAorLOCAL from './views/test/regulations/SelectCustomGVAorLOCAL';
import CustomTest from './views/test/customTest/CustomTest';
import PillTrialMode from './components/pillTrialMode/pillTrialMode';
import ValidateEmailView from './views/ValidateEmail';
import { NoSubscriptionModal } from "./components/home/SubscriptionFailureModals";

const RequireAuth = () => {
  const location = useLocation();
  const data = storageService.getData(USER_DATA);
  if (!data?.token) return <Navigate to='/login' state={{ path: location.pathname }} />;
  else return <Outlet />;
};

const RequireAdmin = () => {
  const location = useLocation();
  const data = storageService.getData(USER_DATA);
  if (data?.user.privileges != "admin") return <Navigate to='/' state={{ path: location.pathname }} />;
  else return <Outlet />;
};

const RequireNoAuth = () => {
  const location = useLocation();

  const data = storageService.getData(USER_DATA);
  if (!data?.token) return <Outlet />;
  return <Navigate to='/' state={{ path: location.pathname }} />;
};

const RequireSubscription = () => {
  const navigate = useNavigate();
  const data = storageService.getData(USER_DATA);

  if (
    !data?.user?.isTrialDayActive && 
    data?.user?.stripe_subscription_status != 'active' &&
    data?.user?.privileges != 'admin'
  ) {
    return (
      <>
        <NoSubscriptionModal onClose={() => navigate('/') } />
      </>
    );
  }
  else return <Outlet />;
};

const Router = () => {

  const location = useLocation();

  const [scrollAtTop, setScrollAtTop] = React.useState(true);

  function handleScroll(e) {
    if (e.target.scrollTop === 0) setScrollAtTop(true);
    else setScrollAtTop(false);
  }

  const pathTohiddeNavBar = ['tests', 'doTestUnfinished', 'customTest']



  return (
    <div className="flex flex-col h-screen overflow-y-auto overflow-x-hidden" onScroll={handleScroll}>
      {!pathTohiddeNavBar.some(path => location.pathname.includes(path)) && (
        <NavigationBar shadow={!scrollAtTop} />
      )}
      {<PillTrialMode
      />}
      <Routes>
        <Route path='/' element={<HomeView />} />
        <Route path='FAQS' element={<FAQSView />} />
        <Route path='payment' element={<PaymentStatusView />} />


        <Route element={<RequireSubscription />} path='customTest'>
          <Route index={true} element={<CustomTest />} />
          <Route path="doCustomTest" element={<TestView />} />
        </Route>

        <Route element={<RequireSubscription />} path="testUnfinished">
          <Route index={true} element={<TesUnfinishedView />} />
          <Route path="doTestUnfinished" element={<TestView />} />
        </Route>

        <Route element={<RequireSubscription />} path='tests'>
          <Route path='simulacrum'>
            <Route index={true} element={<SelectSimulacrum />} />
            <Route path='GVA' element={<SelectGVA />} />
            <Route path='LOCAL' element={<SelectLocal />} />
          </Route>
          <Route path='trialing'>
            <Route path=':id' element={<TestView />} />
          </Route>

          <Route index={true} element={<SelectTest />} />
            <Route path='regulations'>
              <Route index={true} element={<SelectCustomOrlaw />} />
              <Route path='law' element={<SelectSubject />} />
              <Route path='custom' element={<SelectCustomGVAorLOCAL />} />
              <Route path=':subjectblock' element={<SelectStandard />} />
              <Route path=':subjectblock/:standard/:size' element={<TestView />} />
            </Route>
            <Route path=':name' element={<TestView />} />
            <Route path='questions'>
              <Route path='failed/:failed' element={<TestView />} />
              <Route path='notAnswered/:notAnswered' element={<TestView />} />
          </Route>
        </Route>

        <Route element={<RequireNoAuth />}>
          <Route path="login" element={<LoginView />} />
          <Route path="register" element={<RegisterView />} />
          <Route path='/send/recover/password' element={<RecoverPwd />} />
          <Route path='/recover/password' element={<ResetPwd />} />
          <Route path='/validate-email' element={<ValidateEmailView />} />

        </Route>

        <Route element={<RequireAuth />}>
          <Route path='statistics' element={<StatisticsView />} />
          <Route path="profile" element={<ProfileView />} />
          <Route path="reports" element={<ReportReviewerPanelView />} />
        </Route>


        <Route element={<RequireAdmin />}>
          <Route path="admin" element={<AdminPanelView />} />
        </Route>

        <Route path='*' element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default Router;