import freeAutentica from "../../../../../assets/images/autenticatest_gratis.jpg";
import pruebaIcon from "../../../../../assets/icons/iconFreeTest.png";
import MainButton from "../../../buttons/MainButton";
import { TRANSPARENT_BUTTON } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";

export default function FirstSection() {
    const navigate = useNavigate();

    return (
        <div className="px-8 xl:px-60 flex justify-center">
            <div className="flex flex-col sm:flex-row items-start justify-evenly pb-12 border-b" style={{ borderColor: "#7EBEC6" }}>
                <img src={freeAutentica} alt={"App Auténtica Test"} className="max-w-sm sm:max-w-md lg:max-w-lg mx-auto sm:mx-0"/>
                <div className="sm:pl-6">
                    <h1 className="text-4xl text-text-100 font-semibold" style={{ fontFamily: "serif" }}>
                        ¡Queda menos de un mes!
                    </h1>
                    <h1 className="text-4xl text-primary-200 font-semibold" style={{ fontFamily: "serif" }}>
                        ¡Prueba la plataforma 24h gratis!
                    </h1>
                    <h3 className="text-2xl text-text-300 font-light">Simulacros de examen y normativa</h3>
                    <div className="mt-4 text-text-500 text-xl font-semibold">
                        Adaptada a convocatorias de la Comunidad Valenciana:
                        <br></br>Generalitat y Entidades Locales.
                    </div>
                    <div className="mt-3 text-text-500" style={{lineHeight: "1.7"}}>
                        Aprovecha la opción de simulacro para el examen de acceso al <b>cuerpo C1-01 de <br/>GVA del próximo <span className="text-primary-200">sábado 25 de enero.</span></b>
                    </div>
                    <div className="mt-3 text-text-500" style={{lineHeight: "1.7"}}>
                        Y repasa de cara al examen del día <b><span className="text-primary-200">15 de febrero</span> para el acceso a los <br/>cuerpos A2-01 y A1-01.</b>
                    </div>
                    <div className="mt-3 text-text-500" style={{lineHeight: "1.7"}}>
                        Confía en Autenticatest para tu preparación de acceso a GVA y entidades locales.
                    </div>
                    <div className="text-sm mt-6 text-text-500" style={{lineHeight: "1.7"}}>
                        *Promoción válida hasta el domingo 2 de febrero a las 00:00h.
                    </div>
                    <MainButton
                        type={TRANSPARENT_BUTTON}
                        className="px-0 mt-5 mb-5"
                        onClick={() => {
                            navigate("/register");
                        }}
                    >
                        <h1 className="text-4xl text-primary-100 font-semibold">Prueba Gratis</h1>
                        <img className="w-11 h-11" src={pruebaIcon} alt="image of de Auténtica test app" />
                    </MainButton>
                </div>
            </div>
        </div>
    );
}
