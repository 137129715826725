
import { useEffect, useState } from 'react';
import MainButton from '../components/buttons/MainButton';
import { Spinner } from 'flowbite-react';
import PaymentService from '../services/httpServices/payment.service';
import UserService from '../services/httpServices/user.service';
import paymentServiceStripe from '../services/paymentStripe.service';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import ToastService from '../services/toastService';
import { USER_DATA } from '../services/storageService/storageKeys';
import { storageService } from '../services/storageService/storageService';


export default function ValidateEmailView(): JSX.Element {

  const { createCheckoutSession } = PaymentService();
  const { showSuccessToast, showErrorToast } = ToastService();
  const { validateEmail, registerUserWithTrial } = UserService();
  const navigate = useNavigate();

  const [validationError, setValidationError] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<{ email: string } | null>(null);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const shadowStyle = "sm:shadow-xl";

  useEffect(() => {
    try{
      const decodedToken : any = jwt_decode(token!);
      setUserInfo(decodedToken)
      validateEmail(token, {email: decodedToken.email})
    } catch(error) {
      console.log("ERROR", error)
      setValidationError(true)
    }

  }, []);

  const subscriptionHandler = async () => {
    try {
      const createSesion = await createCheckoutSession(userInfo?.email);
      await paymentServiceStripe.redirectToCheckout({ sessionId: createSesion.id });
    } catch (error) {
      setValidationError(true)
    }

  }

  const trialModeHandler = async () => {
    try {
    await registerUserWithTrial(userInfo);
    showSuccessToast(`Se ha completado el registro correctamente. Inicia sesión para acceder a la plataforma.`);
    navigate('/login');
    } catch (error) {
      showErrorToast(`Ha ocurrido un problema al completar el registro. Si el problema persiste póngase en contacto con nosotros`);
    }
  }

  return (
    <div className='flex-1 flex justify-center items-center sm:pb-20 border-t-[1px] border-gray-200'>
      <div className={`w-full flex justify-center flex-col p-10 bg-white rounded-lg sm:max-w-xl ${shadowStyle}`}>
        <h1 className="text-3xl font-semibold text-center text-primary-100 m-5">
          Registro
        </h1>
        {isLoading ?
          <div className='text-center my-6'>
            <Spinner className="fill-primary-100"/>
          </div>:<><>
          {!validationError ?
            <>
              <p className="text-base font-semibold text-center text-text-200 mb-5">
                Para terminar el registro seleccione uno de los siguientes métodos de suscripción
              </p>
              <><div>
                  <MainButton
                    isSubmitButton={true}
                    value="subscription"
                    name="subscription"
                    className="w-full"
                    onClick = {subscriptionHandler}
                  >
                    Acceder al pago
                  </MainButton>
                  <MainButton
                    value="trial-mode"
                    isSubmitButton={true}
                    name="trial-mode"
                    className="w-full mt-3"
                    onClick = {trialModeHandler}
                  >
                    Probar durante 24 horas
                  </MainButton>
                </div>
              </>
            </> :
              <p className="text-base font-semibold text-center text-text-200 mb-5">
                El token de validación no es correcto o ha caducado, vuelva a realizar el registro en la plataforma para validar el correo. Si el problema persiste, póngase en contacto con nostros.
              </p>
            }</></>
        }
      </div>
    </div>
  );
}
