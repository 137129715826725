import { Dispatch, Fragment, SetStateAction, useState } from "react";
import errorService from "../../..//services/error.service";
import { Button, Modal, Textarea, Label} from 'flowbite-react';
import SuccessModal from "../../modals/successModal";
import FailedModal from "../../modals/failedModal";
import ReportService from "../../../services/httpServices/report.service";


export default function ModalQuestionReport({
  setOpenModalReportInfo,
  question,
  openModal

}: {
    setOpenModalReportInfo: Dispatch<SetStateAction<boolean>>
    question: string
    openModal: boolean

  }): JSX.Element {

  const { addReport } = ReportService();

  const [reportErrors, setReportErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [showSuccessModal, setShowSucessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);

  const close = () => {
    setOpenModalReportInfo(false);
  };

  const reportQuestion = async () => {
    const textarea = document.getElementById("report") as HTMLTextAreaElement;
    const report = textarea.value;
    if (report && report.length > 0) {
      try {
        await addReport({description: report, question});
        setShowSucessModal(true);
      } catch (error) {
        const castedError = errorService.castError(error);
        setShowFailedModal(true);
        setReportErrors([JSON.stringify(castedError.message)]);
        setShowErrors(true);
      }
    }
  };

  return (
      <Fragment>
        {
          !showSuccessModal && !showFailedModal
            ?
            <Fragment>
              <Modal
                show={openModal}
                size="xl"
                popup={true}
                onClose={close}
              >
                <Modal.Header />
                <Modal.Body>
                  <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    <h3 className="text-xl font-medium text-gray-900">
              Reportar pregunta
                    </h3>
                    <div>
                      <div className="mb-2 block">
                        <Label
                          htmlFor="report"
                          value="Escribe el reporte" />
                      </div>
                      <Textarea
                        id="report"
                        placeholder="Escribe aquí tu reporte"
                        required={true}/>
                    </div>
                    {reportErrors && showErrors && (
                      <div className="flex flex-row items-center pt-1 text-red-600	">
                        <span className="font-base bg-yellow-50 text-sm text-red-600">{reportErrors}</span>
                      </div>
                    )}
                    <div className="flex justify-center gap-4">
                      <Button
                        color="gray"
                        onClick={close}
                        className='hover:bg-gray hover:text-black'
                      >
                Cancelar
                      </Button>
                      <Button
                        onClick={reportQuestion}
                        className='bg-primary-100 font-medium text-white hover:border-[#696969] hover:bg-white hover:text-black disabled:opacity-50 hover:disabled:bg-[#FCE96A]'
                      >
                Aceptar
                      </Button>
                    </div>

                  </div>
                </Modal.Body>
              </Modal>
            </Fragment>
            :
            showSuccessModal ?
              <SuccessModal
                info={'Gracias por tu aportación, lo revisaremos cuanto antes.<br/>Recuerda que no se resuelven dudas a través de Auténtica Test.'}
                setOpenModal={setShowSucessModal}
                accept={close}
                openModal={showSuccessModal}/>
              :
              showFailedModal ?
                <FailedModal
                  info={'Se ha producido un error en reportar esta pregunta, porfavor inténtelo de nuevo'}
                  setOpenModal={setShowFailedModal}
                  accept={close}
                  openModal={showFailedModal}/>
                :
                <Fragment>
                  <Modal
                    show={openModal}
                    size="xl"
                    popup={true}
                    onClose={close}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                        <h3 className="text-xl font-medium text-gray-900">
                Reportar pregunta
                        </h3>
                        <div>
                          <div className="mb-2 block">
                            <Label
                              htmlFor="report"
                              value="Escribe el reporte" />
                          </div>
                          <Textarea
                            id="report"
                            placeholder="Escribe aquí tu reporte"
                            required={true} />
                        </div>
                        {reportErrors && showErrors && (
                          <div className="flex flex-row items-center pt-1 text-red-600	">
                            <span className="font-base bg-yellow-50 text-sm text-red-600">{reportErrors}</span>
                          </div>
                        )}
                        <div className="flex justify-center gap-4">
                          <Button
                            color="gray"
                            onClick={close}
                            className='hover:bg-gray hover:text-black'
                          >
                  Cancelar
                          </Button>
                          <Button
                            onClick={reportQuestion}
                            className='bg-primary-100 font-medium text-white hover:border-[#696969] hover:bg-white hover:text-black disabled:opacity-50 hover:disabled:bg-[#FCE96A]'
                          >
                  Aceptar
                          </Button>
                        </div>

                      </div>
                    </Modal.Body>
                  </Modal>
                </Fragment>


        }

      </Fragment>
  );
}
